import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Card, Container, Divider, Image } from 'semantic-ui-react'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <Link to={`/${article.slug}`}>
    <Card href={`/${article.slug}` }>
      <div style={{padding: '4px'}}>
        <Img alt="" fluid={article.heroImage.fluid} />
      </div>
    </Card>
  </Link>
)
